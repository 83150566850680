import { IForm } from '@/base-ui/form/types'

export const modalConfig: IForm = {
  labelWidth: '130px',
  formItems: [
    {
      field: 'credit_code',
      type: 'input',
      label: '社会信用统一代码',
      placeholder: '请输入社会信用统一代码'
    },
    {
      field: 'name',
      type: 'input',
      label: '企业名称',
      placeholder: '请输入企业名称'
    },
    {
      field: 'boss_name',
      type: 'input',
      label: '法人代表',
      placeholder: '请输入法人代表'
    },
    {
      field: 'phone',
      type: 'input',
      label: '电话',
      placeholder: '请输入电话'
    },
    {
      field: 'email',
      type: 'input',
      label: '邮箱',
      placeholder: '请输入邮箱'
    },
    {
      field: 'register_address',
      type: 'input',
      label: '注册地址',
      placeholder: '请输入注册地址'
    },
    {
      field: 'contact_address',
      type: 'input',
      label: '联系地址',
      placeholder: '请输入联系地址'
    },
    {
      field: 'wx',
      type: 'input',
      label: '微信',
      placeholder: '请输入微信'
    },
    {
      field: 'admin_phone',
      type: 'input',
      label: '管理员账号',
      placeholder: '请输入管理员账号',
      remark: '默认密码为123456'
    }
    // {
    //   field: 'cellphone',
    //   type: 'checkbox',
    //   otherOptions: {
    //     label: '发送短信通知企业管理员'
    //   }
    // }
  ],
  colLayout: {
    span: 12
  },
  itemStyle: {}
}
