import { IForm } from '@/base-ui/form/types'

export const searchFormConfig: IForm = {
  formItems: [
    {
      field: 'name',
      type: 'input',
      placeholder: '企业名称'
    }
  ]
}
export const detailSearchFormConfig: IForm = {
  formItems: [
    {
      field: 'status',
      type: 'select',
      placeholder: '状态',
      options: [
        { title: '全部', value: '' },
        { title: '待审核', value: 1 },
        { title: '审核失败', value: 2 },
        { title: '上架中', value: 3 },
        { title: '已下架', value: 4 }
      ]
    },
    {
      field: 'name',
      type: 'input',
      placeholder: '服务名称'
    }
  ]
}
