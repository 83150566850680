export const contentTableConfig = {
  addBtnTxt: '新建商家',
  propList: [
    { prop: 'id', label: '编号' },
    { prop: 'name', label: '企业名称' },
    { prop: 'active_status', label: '激活状态' },
    { prop: 'create_time', label: '时间' },
    { prop: 'people_num', label: '企业账号人数' },
    { prop: 'contact_address', label: '地区' },
    { prop: 'service_num', label: '发布服务数量(全部)' },
    { prop: 'order_num', label: '订单' },
    { label: '操作', slotName: 'handler' }
  ]
}
